@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700&display=swap');
@import "/node_modules/video-react/dist/video-react.css";

:root {
  --primary: #000;
  --hover: #ec3740;
  --link: #bc9109;
}

body {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  color: #444444 !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (min-width: 500px) {
  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background-color: rgba(0, 0, 0, 0.884) !important;
  }
  
  ::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px !important;
    border-radius: 10px !important;
    background-color: var(--link);
  }
  .admin-content ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background-color: rgba(212, 212, 212, 0.603) !important;
  }
  
  .admin-content ::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px !important;
    border-radius: 10px !important;
    background-color: var(--link);
  }
}

.text-info {
  text-align: left;
  padding-left: 10px;
  font-size: 10px;
  text-transform: uppercase;
  color: #00000080;
  cursor: default;
}

.text-area textarea {
  text-align: justify !important;
}

.no-data {
  color: #36363680;
  text-transform: uppercase;
  font-size: 10px;
}

.tag {
  padding: 3px 15px 3px 3px !important;
  /* border: 1px solid rgba(0, 0, 0, 0.3); */
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  margin-right: 5px;
  margin-top: 5px;
  color: white;
  font-weight: 600;
}

.btn-primary {
  background-color: var(--primary);
}

.btn-primary:hover, .btn-primary:focus {
  background-color:  var(--link) !important;
}

.admin-sidebar {
  height: 100vh;
  overflow-y: auto;
  padding-bottom: 100px !important;
  background-color: #FFF;
  box-shadow: 1px 1px 10px #00000046;
  padding: 0px 5px;
  z-index: 8 !important;
}


.admin-sidebar-item {
  width: 100%;
}

.admin-sidebar-item-link {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  color: #000;
}

.admin-sidebar-item-link.active svg{
  color: var(--link);
}

.admin-sidebar-item-link:hover, .admin-sidebar-item-link:focus {
  background-color: #00000011;
  color: #000 !important;
}

.admin-sidebar-item-link svg {
  margin-right: 10px;
  color: #0000009a;
}


.admin-navbar {
  height: 60px;
  background-color: #000;
  box-shadow: 1px 1px 10px #00000046;
  z-index: 9 !important;
  position: relative;
  padding: 0px 20px;
}


.admin-content {
  height: 100vh;
  overflow-y: hidden;
}

.site-content {
  overflow-x: hidden;
}

.admin-main-row {
  padding: 10px 10px 0px 10px;
  height: 100vh;
  overflow-y: auto;
  background-color: #b3b3b323;
}

.admin-main-row > .ant-row {
  margin-bottom: 100px;
}

@media (min-width: 768px) {
  .menu-button {
      display: none !important;
  }
  .admin-main-row {
    padding: 30px 60px 0px 60px;
  }
}


.btn-nav {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  margin-right: 8px;
}

.btn-nav:hover, .btn-nav:focus {
  color: var(--link) !important;
}


.ant-checkbox:focus, .ant-checkbox:hover, .ant-checkbox-inner:hover, .ant-checkbox-inner:focus, .ant-checkbox-checked:focus {
  border-color: var(--link) !important;
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--link) !important;
  border-color: var(--link) !important;
}

.ant-btn-primary, .btn-primary {
  background-color: var(--primary);
}

.ant-btn-primary:hover, .btn-primary:hover, .ant-btn-primary:focus, .btn-primary:focus {
  background-color: var(--link) !important;
}


.ant-btn-default, .btn-default {
  background-color: #FFF;
}

.ant-btn-default:hover, .btn-default:hover, .ant-btn-default:focus, .btn-default:focus {
  border-color: var(--link) !important;
  color: var(--link) !important;
}

.page-content {
  margin-top: 18px;
  padding-bottom: 50px;
}

.page-bread {
  color: #0000006b;
}

.page-bread.active {
  color: var(--link);
  font-weight: 700;
}

.pic-favorite {
  border-color: rgb(3, 196, 3) !important;
}

.site-nav {
  background-color: #000;
  padding: 3px 60px;
  color: #FFF;
  transition: 0.3s;
}

.site-nav-comp {
  transition: 0.3s;
}

.site-nav-pos.hidden .site-nav {
  background-color: transparent;
}

.site-nav-pos.hidden .site-nav-comp {
  opacity: 0;
}

.site-nav-phone {
  font-family: "Vidaloka", serif;
  text-align: center;
  color: #fff;
  font-size: 14px;
}

.site-nav-address {
  font-family: "Vidaloka", serif;
  text-align: center;
  color: #ffffffb6;
  font-size: 22px;
  border: #000 2px solid;
  border-radius: 5px;
  padding-top: 10px;
}

.site-nav-pos {
  position: fixed;
  width: 100%;
  z-index: 9999;
}

.btn-nav {
  color: #fff;
}

.btn-nav-menu {
  border-top: #fff 2px solid;
  border-bottom: #fff 2px solid;
  padding-top: 20px;
  padding-bottom: 20px;
  border-right: transparent 5px solid;
  border-left: transparent 5px solid;
  width: 60px;
}

.btn-nav-menu:hover {
  border-top: var(--link) 2px solid;
  border-bottom: var(--link) 2px solid;
}

.site-home-filter {
  position: absolute !important;
  width: 100vw !important;
  height: 100% !important;
  background-color: #000000b7 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  flex-direction: column;
}

.site-home-no-filter {
  position: absolute !important;
  width: 100vw !important;
  height: 100% !important;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  flex-direction: column;
}

.site-home-title {
  font-size: 120px;
  color: var(--link);
  letter-spacing: 30px;
}

.site-home-subtitle {
  font-size: 18px;
  color: #FFF;
  letter-spacing: 10px;
  margin-top: 80px;
  margin-bottom: 10px;
}

.social {
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

.social svg {
  transition: 0.2s;
}

.social:hover svg, .social:focus svg {
  color: var(--link) !important;
}

.site-home-about {
  color: #ffffff7a;
  font-size: 18px;
  font-weight: 100;
  padding-right: 80px;
  text-align: justify;
}

.site-home-about + .site-home-about {
  margin-bottom: 15px;
}

.site-home-about-v2 {
  color: var(--link);
  font-size: 18px;
  letter-spacing: 8px;
}

.site-home-about-bold {
  color: #FFF;
  font-size: 18px;
  font-weight: 700;
  padding-right: 80px;
  text-align: justify;
  margin-bottom: 15px;
}

.site-home-about-title {
  color: var(--link);
  font-size: 28px;
  padding-right: 80px;
  text-align: justify;
  margin-bottom: 15px;
}

.site-container {
  background-color: #1a1a1a;
}

.site-home-model {
  color: var(--link);
  font-size: 30px;
  margin-top: -80px;
  margin-bottom: -30px;
}

.site-home-model-fav {
  color: #fff;
  font-size: 60px;
}

.site-home-model-sub {
  color: #ffffff9a;
  font-size: 18px;
  margin-top: 10px;
  margin-bottom: 20px;
}

.site-model-title {
  text-align: center;
  color: #fff;
  font-size: 50px;
  margin-bottom: 20px;
}

.site-model-subtitle {
  text-align: center;
  color: var(--link);
  font-size: 30px;
  margin-bottom: 10px;
}

.model-container {
  z-index: 100 !important;
  width: 100%;
  height: 450px;
  padding: 15px;
  overflow: hidden !important;
}

.model-content {
  background-color: #fff;
  width: 100%;
  height: 420px;
  border-radius: 12px;
  overflow: hidden;
  background-position: center !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  z-index: 100 !important;
}

.model-filter {
  transition: 0.3s;
  height: 100%;
  width: 100%;
  z-index: 10;
  border-radius: 10px;
}

.model-content:hover .model-filter {
  background-color: #00000085;
}

.model-dados {
  transition: 0.3s;
}

.model-content:hover .model-dados {
  margin-top: -150px;
}

.model-name {
  text-align: center;
  color: #FFF;
  font-size: 40px;
  text-transform: uppercase;
  margin-bottom: -20px;
}

.model-lastname {
  text-align: center;
  font-size: 20px;
  color: #FFF;
  text-transform: uppercase;
  margin-bottom: -24px;
}

.model-cont-dados {
  background-color: #000;
  height: 110px;
  padding: 5px 10px;
  padding-top: 24px;
}

.model-dados-title {
  color: var(--link);
  text-align: center;
  margin-bottom: -6px;
  font-size: 10px;
}

.model-dados-data {
  color: #fff;
  text-align: center;
  font-size: 11px;
  text-transform: uppercase;
}